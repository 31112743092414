import React from 'react';
import ReactDOM from 'react-dom';


export default function Main (){
  return (
    <div id="home" className="top-image p-relative h-100">
        <div className="name-div d-flex justify-content-center align-items-center flex-column">
          <h1 className="text-white h1-lg">ANDIE SWIFT</h1>
          <h1 className= "text-white h1-sm">Software Engineer</h1>
        </div>
      <div className="top-right"></div>
      <div className="bottom-left"></div>
    </div>
  );
}
